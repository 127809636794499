<template>
  <div>
    <div class="container">
      <p class="title-text">认证信息</p>
      <div class="main_from">
        <div class="item">
          <span class="item_name">企业名称</span>
          <div class="ico_con">
            <el-input v-model="data.company" placeholder="输入企业名称" style="width: 360px" disabled></el-input>
          </div>
        </div>
        <div class="item">
          <span class="item_name">统一社会信用代码</span>
          <div class="ico_con">
            <el-input v-model="data.identification" placeholder="输入统一社会信用代码" style="width: 360px" disabled></el-input>
          </div>
        </div>
        <div class="item">
          <span class="item_name">营业执照</span>
          <div class="ico_con">
            <img :src="data.business_image" alt="">
          </div>
        </div>
        <div class="item">
          <span class="item_name">法定代表人姓名</span>
          <div class="ico_con">
            <el-input v-model="data.corporation" placeholder="输入法定代表人姓名" style="width: 360px" disabled></el-input>
          </div>
        </div>
        <div class="item">
          <span class="item_name">身份证正面(人像面)</span>
          <div class="ico_con">
            <img :src="data.front_image" alt="">
          </div>
        </div>
        <div class="item">
          <span class="item_name">身份证反面(国徽面)</span>
          <div class="ico_con">
            <img :src="data.back_image" alt="">
          </div>
        </div>
        <div class="item">
          <span class="item_name">提交时间</span>
          <div class="ico_con">
            <el-input v-model="data.create_time" placeholder="输入提交时间" style="width: 360px" disabled></el-input>
          </div>
        </div>
      </div>
      <p class="title-text" style="margin-top: 40px">审核信息</p>
      <div class="main_from">
        <div class="item">
          <span class="item_name">审核结果</span>
          <div class="ico_con">
            <el-input v-model="input" placeholder="输入审核结果" style="width: 360px" disabled></el-input>
          </div>
        </div>
        <div class="item" v-if="data.status === 3">
          <span class="item_name">驳回原因</span>
          <div class="ico_con">
            <el-input type="textarea" v-model="data.note" placeholder="输入驳回原因" style="width: 360px" maxlength="100" show-word-limit disabled></el-input>
          </div>
        </div>
        <div class="item">
          <span class="item_name">审核时间</span>
          <div class="ico_con">
            <el-input v-model="data.update_time" placeholder="输入审核时间" style="width: 360px" disabled></el-input>
          </div>
        </div>
        <div class="btn_box">
          <a href="JavaScript:;" @click="fallback">返回</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "details",
  data() {
    return {
      data: {},
      input: ""
    }
  },
  created(){
    this.$route.query?.id && this.getEnterpriseInfo(this.$route.query.id);
  },
  methods: {
    // 获取企业信息
    getEnterpriseInfo(id) {
      this.$api.getEntDetail(id).then(res => {
        this.data = res.data
        // 2：审核通过 3：审核失败

        if (this.data.status === 2) {
          this.input = "审核通过"
        } else if (this.data.status === 3) {
          this.input = "审核失败"
        }
      })
    },
    fallback() {
      this.$router.push({path: "/enterpriseCertification", query: {activeName: 'second'}})
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  margin: 18px;
  padding: 30px;
  width: calc(100% - 96px);
  height: calc(100% - 96px);
  background: #FFFFFF;
  overflow: scroll;
  scrollbar-width: none;
  .title-text {
    font-size: 18px;
    color: #242D33;
    position: relative;
    margin-bottom: 15px;
    &:before {
      position: absolute;
      content: '';
      left: -30px;
      top: 4px;
      width: 4px;
      height: 20px;
      background: #3984F5;
    }
  }
  // tags样式修改
  ::v-deep .el-tabs__nav-wrap::after {
    background: #EEEFF2;
    height: 1px;
  }
  ::v-deep .el-tabs__item.is-active {
    color: #3984F5;
    font-weight: bold;
  }
  ::v-deep .el-tabs__item {
    &:hover {
      color: #3984F5;
    }
  }
  .main_from {
    .btn_box {
      a {
        width: 120px;
        height: 40px;
        background: #EEF1F3;
        border-radius: 4px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #6C7880;
        display: block;
        line-height: 36px;
        text-align: center;
        margin-left: 140px;
        margin-top: 40px;
      }
    }
    .item {
      display: flex;
      margin-top: 18px;
      .item_name {
        width: 130px;
        text-align: right;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 12px;
        color: #21333F;
        line-height: 36px;
        margin-right: 12px;
        i {
          color: #FD5451;
          margin-right: 2px;
        }
      }
      .ico_con {
        img {
          width: 120px;
          height: 120px;
        }
      }
      ::v-deep .el-input__inner {
        height: 34px;
        line-height: 34px;
      }
      ::v-deep .el-textarea__inner {
        height: 80px;
      }
    }
  }
}
</style>